import { FC, useMemo, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { FileUpload } from '@aurecon-creative-technologies/styleguide'
import {
  dropzoneHeight,
  dropzoneWidth,
  sizeLimit,
  fileUnits,
  useSizeLimitMessage,
  fileTypes,
  useAllowedFileExtensionsMessage,
  DropzoneTooltip,
  DropzoneLabel,
  fileActionRenderer,
} from '../../config/fileUploadConfig'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import PDFViewer from '../PDFViewer'
import { useLanguages } from '../../hooks/useLanguages'

import { useMediaQuery } from 'react-responsive'
import { UI_FULL_WIDTH, UI_WIDTH_COLLAPSE } from '../../config/config'

import Style from '../../styles/ManageFileModal.module.sass'

interface IManageFileModalProps {
  open: boolean
  file: File | null
  viewerId?: string
  onClose: () => void
  onUpdate: (file: File) => void
}

const ManageFileModal: FC<IManageFileModalProps> = ({ open, file, onClose, onUpdate }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const fullScreen = useRecoilValue(FullScreen)
  const sizeLimitMessage = useSizeLimitMessage()
  const allowedFileExtensionsMessage = useAllowedFileExtensionsMessage()
  const { t } = useLanguages()
  const labelYes = t('update')

  const onModalClosed = () => {
    onClose()
  }

  const handleUpdate = () => {
    if (uploadedFile) {
      onUpdate(uploadedFile)
    }
  }

  const handleFileChange = (files: File[]) => {
    const newFile = files.length ? files[0] : null
    setUploadedFile(newFile)
  }
  const renderPDFViewer = (renderFile?: File | string) => {
    return (
      <PDFViewer
        file={URL.createObjectURL(renderFile as File)}
        showOnlyAsPreview={false}
        height={viewerHeight}
        viewerId={`viewer-manage-${name}`}
      />
    )
  }

  const fileUploadProps = {
    initialFiles: file ? [new File([file], file.name, { type: file.type })] : [],
    dropzoneLabel: <DropzoneLabel />,
    allowedFileExtensions: fileTypes,
    allowedFileExtensionsMessage,
    dropzoneTooltip: <DropzoneTooltip />,
    onChange: handleFileChange,
    fileRenderer: renderPDFViewer,
    sizeLimitMessage,
    fileActionRenderer: fileActionRenderer,
    height: dropzoneHeight,
    width: dropzoneWidth,
  }

  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const needFullWidth = useMediaQuery({ minWidth: UI_FULL_WIDTH })

  const viewerHeight = useMemo(() => {
    if (needFullWidth) return 400
    if (isDesktop) return 250
    return 150
  }, [isDesktop, needFullWidth])

  return (
    <RecallModal
      isShowing={open}
      onClose={onModalClosed}
      chatType={ChatTypeEnum.BAMBOO}
      disabled={!uploadedFile}
      onSave={handleUpdate}
      labelYes={labelYes}
      size={fullScreen ? 'medium' : 'small'}
    >
      <div className={Style.uploadArea}>
        <h3>{t('upload_file')}</h3>
        <p className={Style.uploadSubtitle}>{t('upload_desc')}</p>
        <FileUpload {...fileUploadProps} sizeLimit={[sizeLimit, fileUnits]} height={`${viewerHeight}px`} />
      </div>
    </RecallModal>
  )
}

export default ManageFileModal
