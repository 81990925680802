import { FC, useEffect, useRef, useState } from 'react'
import Style from '../../styles/common/SearchComboBox.module.sass'
import { ComboBox, Grid, IOption, Pill } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import debounce from 'debounce'
import { searchUsers } from '../../api/UserService'
import { ResponseData } from '../../models/api/IResponse'

interface IUserComboBoxProps {
  onSelectedItem: (users: IOption[]) => void
  selectedItems: IOption[]
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  error?: string
  excludeIds?: string[]
}

const UserComboBox: FC<IUserComboBoxProps> = (props) => {
  const { label, placeholder, onSelectedItem, required, disabled, selectedItems, error, excludeIds } = props
  const [users, setUsers] = useState<IOption[]>()
  const [selectedUsers, setSelectedUsers] = useState<IOption[]>([])
  const originatorRef = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [val, setVal] = useState('')

  useEffect(() => {
    setSelectedUsers(selectedItems)
  }, [selectedItems])

  const handleRemoveOriginator = (key: string) => {
    const updatedOrignators = selectedUsers.filter((x) => x.id !== key)
    setSelectedUsers(updatedOrignators)
    onSelectedItem(updatedOrignators)
  }

  const handleAddOriginator = (newOption: IOption | null) => {
    if (!newOption || !originatorRef.current) return
    if (selectedUsers.find((s) => s.id === newOption.id)) return
    const updatedOrignators = [...selectedUsers, newOption]
    setSelectedUsers(updatedOrignators)
    onSelectedItem(updatedOrignators)
    originatorRef.current.value = ''
    setUsers([])
    setVal('')
    originatorRef.current.blur()
  }

  const onSearch = debounce(async (event: React.ChangeEvent<HTMLDivElement>) => {
    if (!originatorRef.current) return
    const value = (event.target as HTMLInputElement).value
    if (val === value) return
    setVal(value)
    originatorRef.current.value = value
    if (value.length < 3) {
      setUsers([])
      return
    }
    setLoading(true)
    const userList = ResponseData(await searchUsers({ email: value })) || []
    const filteredUsers = userList.filter(
      (user) => !selectedUsers.map((so) => so.id).includes(user.auth0UserId) && !excludeIds?.includes(user.auth0UserId),
    )

    setUsers(
      filteredUsers
        .map((u) => ({ id: u.auth0UserId ?? '', value: u.email ?? '', display: `${u.name ?? ''} (${u.email ?? ''})` }))
        .slice(0, 10),
    )
    setLoading(false)
  }, 500)

  const originatorPillClasses = classNames({
    [Style.metadataPill]: true,
    [Style.item]: true,
  })

  const comboBoxClasses = classNames({
    [Style.comboBox]: true,
    [Style.loading]: loading,
  })

  const showOriginatorPills = () => {
    return selectedUsers.map((item) => (
      <Pill
        key={item.id}
        size='medium'
        cssClass={originatorPillClasses}
        onClose={() => handleRemoveOriginator(item.id.toString())}
      >
        {(item.display as string).split('(')[0].trim()}
      </Pill>
    ))
  }

  return (
    <Grid item xs={12} cssClass={Style.metadataRow}>
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        <div className={Style.metadataFieldlabel}>{label}</div>
      </Grid>
      {selectedUsers.length > 0 && (
        <Grid item xs={12} cssClass={Style.metadataFieldRow}>
          <div className={Style.metadataPillsBox}>{showOriginatorPills()}</div>
        </Grid>
      )}
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        <div className={comboBoxClasses} onChange={onSearch}>
          <ComboBox
            options={users || []}
            onSelect={handleAddOriginator}
            onClear={() => setUsers([])}
            required={required}
            selected={val}
            disabled={disabled}
            placeholder={placeholder}
            ref={originatorRef}
            icon={loading ? 'sync' : undefined}
          />
        </div>
        {error && <p className={Style.errorMessage}>{error}</p>}
      </Grid>
    </Grid>
  )
}

export default UserComboBox
