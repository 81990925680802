import { getAsync, postAsync } from '../helpers/apiRequest'
import { ICustomAppShareResponseModel } from '../models/api/ICreateChatModels'
import { ICreateGetCraRequestModel, ICreateShareCraRequestModel } from '../models/api/ICreateCustomRecallShareModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

export const createCustomRecallAppShare = TokenExpiryWrapper(
  (request: ICreateShareCraRequestModel): Promise<IResponse<ICustomAppShareResponseModel[]>> =>
    postAsync('/v1/craShare', request),
  [],
  null,
)

export const getAllCustomAppShares = TokenExpiryWrapper(
  (request: ICreateGetCraRequestModel): Promise<IResponse<ICustomAppShareResponseModel[]>> =>
    getAsync(`/v1/craShare/${request.id}`, request),
  [],
  null,
)
