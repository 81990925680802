import { getAsync, postAsync, putAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { ICustomAppResponseModel } from '../models/api/ICreateChatModels'
import {
  ICheckCraRequestModel,
  IGetCraRequestModel,
  IUpdatedCraRequestModel,
} from '../models/api/ICreateCustomRecallModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

export const createCustomRecallApp = TokenExpiryWrapper(
  (request: ICheckCraRequestModel): Promise<IResponse<ICustomAppResponseModel>> => postAsync('/v1/cra', request),
  [],
  null,
)

export const getAllCustomApps = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ICustomAppResponseModel[]>> => getAsync('/v1/cra', request),
  [],
  null,
)

export const getCustomApp = TokenExpiryWrapper(
  async (request: IGetCraRequestModel): Promise<IResponse<ICustomAppResponseModel>> =>
    getAsync(`/v1/cra/${request.id}`, request),
  [],
  null,
)

export const publishCustomRecallApp = TokenExpiryWrapper(
  (request: IUpdatedCraRequestModel): Promise<IResponse<ICustomAppResponseModel>> => putAsync('/v1/cra', request),
  [],
  null,
)
