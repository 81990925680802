import { io } from 'socket.io-client'
import { getServerUrlForSocket } from '../helpers/utils'
import { IGenericModel } from '../models/api/IBambooChatModel'
export const socket = io(`wss://${getServerUrlForSocket()}`, {
  secure: true,
  autoConnect: false,
})

export const catchSocketEvent = (eventName: string, id: string, callback: CallableFunction) => {
  if (!socket.connected) socket.connect()
  socket.emit(eventName, id)
  socket.on(eventName, (response: IGenericModel) => {
    callback(response)
  })
}

export const disconnectSocket = (eventName: string) => {
  socket.off(eventName)
  socket.disconnect()
}
