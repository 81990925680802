import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { COOKIE_LANGUAGE, UI_WIDTH_COLLAPSE } from '../config/config'

import { Language } from '../stores/AppStore'
import { useRecoilState } from 'recoil'
import { useLanguages } from '../hooks/useLanguages'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import Style from '../styles/LanguageSwitch.module.sass'

interface ILanguageSwitchProps {
  login?: boolean
}

const LanguageSwitch: FC<ILanguageSwitchProps> = (props) => {
  const { login = false } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })

  const [language, setLanguage] = useRecoilState(Language)
  const { languages, i18n } = useLanguages(login)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as Node)) {
        setExpanded(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerRef])

  const selectLanguage = useCallback(
    (value: string) => {
      if (!i18n || !languages) return
      localStorage.setItem(COOKIE_LANGUAGE, value)
      i18n.changeLanguage(value || 'en')
      setLanguage(value)
      setExpanded(false)
    },
    [i18n, languages, setLanguage],
  )

  useEffect(() => {
    if (!i18n || !languages) return
    const cookieLang = localStorage.getItem(COOKIE_LANGUAGE)
    const browserLang = languages?.find((l) => navigator.language.startsWith(String(l.id)))
    const value = String(cookieLang ?? browserLang?.id ?? 'en')
    selectLanguage(value)
  }, [i18n, languages, selectLanguage, setLanguage])

  const renderSelected = () => {
    const lang = languages?.find((l) => language === l.id)

    return !lang ? null : (
      <div role='none' className={Style.label} onClick={() => setExpanded(!expanded)}>
        <img src={`data:image/svg+xml;utf8,${encodeURIComponent(lang.flag)}`} alt='Selected language country flag' />
        {isDesktop && String(lang.id).toUpperCase()}
      </div>
    )
  }

  if (!languages) return null

  const languageItemClasses = (type: string) => {
    return classNames({
      [Style.item]: true,
      [Style.active]: language === type,
    })
  }

  const containerClasses = classNames({
    [Style.languageSwitcherContainer]: true,
    pageThemeDark: login,
  })

  return (
    <div className={containerClasses} ref={containerRef}>
      <div className={Style.selectedlanguage}>{renderSelected()}</div>

      {expanded && (
        <div className={Style.languageItemsContainer}>
          <div className={Style.languageItems}>
            {languages.map((lang) => {
              const langId = String(lang.id)
              return (
                <div
                  role='none'
                  key={langId}
                  className={languageItemClasses(langId)}
                  onClick={() => selectLanguage(langId)}
                >
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(lang.flag)}`}
                    alt='Selected language country flag'
                  />
                  {lang.name}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default LanguageSwitch
