import { FC, useCallback, useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Button, Grid, Icon, Loader, Tooltip } from '@aurecon-creative-technologies/styleguide'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { ChatSession, ChatType, NomicSession, NotFound, QuestionFile, ScrollChat } from '../stores/AppStore'
import { Trans } from 'react-i18next'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import Style from '../styles/Home.module.sass'
import ChatSwitcher from '../components/ChatSwitcher'
import { useParams } from 'react-router-dom'
import { getCustomApp } from '../api/CustomRecallAppService'
import ChatNotFound from '../components/ChatNotFound'
import { ResponseData } from '../models/api/IResponse'

const ChatCraHome: FC = () => {
  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const { t, i18n } = useLanguages()
  const [loading, setLoading] = useState(false)
  const [notFound, setNotFound] = useRecoilState(NotFound)
  const [nomicSession, setNomicSession] = useRecoilState(NomicSession)
  const setScrollChat = useSetRecoilState(ScrollChat)
  const [chatSession, setChatSession] = useRecoilState(ChatSession)

  const { customAppId } = useParams()

  useEffect(() => {
    if (chatSession && chatSession.type === ChatTypeEnum.CUSTOM_RECALL_APP) return
    setChatSession(null)
  }, [setChatSession, chatSession])

  useEffect(() => {
    setChatType(ChatTypeEnum.CUSTOM_RECALL_APP)
    setQuestionFile(null)

    return () => setChatType(null)
  }, [setChatType, setQuestionFile])

  const updateScroll = useCallback(() => {
    setTimeout(() => {
      setScrollChat((s) => s + 1)
    }, 1000)
  }, [setScrollChat])

  useEffect(() => {
    const getSessionAsync = async () => {
      const id = customAppId ?? ''
      const customApp = ResponseData(await getCustomApp({ id }))

      if (!customApp) {
        setLoading(false)
        setNotFound(true)
        setNomicSession(null)
        setChatType(null)
        return
      }

      setChatType(ChatTypeEnum.CUSTOM_RECALL_APP)
      setNomicSession(customApp)
      setLoading(false)
      setNotFound(false)

      updateScroll()
    }

    if (nomicSession?.id === customAppId) return

    setLoading(true)
    getSessionAsync()
  }, [customAppId, nomicSession?.id, setChatType, setNomicSession, setNotFound, setScrollChat, updateScroll])

  if (appInsights) appInsights.trackPageView({ name: 'Custom Recall Chat Home' })

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  if (loading)
    return (
      <Page menu contentWrapper>
        <Loader label='Loading custom recall app...' />
      </Page>
    )
  return (
    <Page menu contentWrapper>
      {notFound || !nomicSession ? (
        <ChatNotFound />
      ) : (
        <div className={Style.centerHolder}>
          <h1 className={Style.chatTitle} title={nomicSession.name}>
            {nomicSession.name}
          </h1>
          <div className={Style.craAppSwitcherContainer}>
            <Tooltip show='Open Nomic Map' cssClass={Style.nomicMapLinkTooltip}>
              <Button
                cssClass={Style.mapLinkButton}
                onClick={() => window.open(nomicSession.mapUrl, '_blank')}
                type='icon-round'
                icon='link'
              />
            </Tooltip>
          </div>
          <ChatSwitcher activeChat={ChatTypeEnum.CUSTOM_RECALL_APP} customSelectedCraLabel={nomicSession.name} />
          <p>
            Recall is a new interface to some of Aurecon's knowledge and expertise, that allows us to quickly and
            accurately retrieve relevant information and answer user queries.
          </p>
          <p>
            Find more information on our hive pages{' '}
            <a
              href='https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/AI-at-Aurecon.aspx'
              target='_blank'
              rel='noreferrer'
              className={Style.craAnchor}
            >
              here.
            </a>
          </p>
          <div className={Style.examples} style={{ textAlign: 'center' }}>
            <h3>{t('navigation_tip')}</h3>
            <div className={Style.craNavIcon}>
              <Icon type='keyboard' outlined />
            </div>

            <Grid row style={{ justifyContent: 'center' }}>
              <div className={Style.tip}>
                <p>
                  <Trans i18nKey='navigation' />
                </p>
              </div>
            </Grid>
          </div>
        </div>
      )}
      <PreparingChatModal />
    </Page>
  )
}

export default ChatCraHome
