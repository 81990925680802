import { FC, useState } from 'react'
import { Grid, Icon, IOption, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import Style from '../../styles/NomicModal.module.sass'
import UserComboBox from '../common/UserComboBox'
import { ICustomAppResponseModel, ICustomAppShareResponseModel } from '../../models/api/ICreateChatModels'
import { format, parseISO } from 'date-fns'
import { twelveHourFormat } from '../../config/config'

interface IManageAccessModalProps {
  open: boolean
  onSave: (users: IOption[], craId: string) => Promise<void>
  onClose: () => void
  cra: ICustomAppResponseModel
  customAppShareList: ICustomAppShareResponseModel[]
  loading?: boolean
}

const ManageAccessModal: FC<IManageAccessModalProps> = (props) => {
  const { open, onClose, loading, cra, customAppShareList, onSave } = props
  const fullScreen = useRecoilValue(FullScreen)
  const [selectedUsers, setSelectedUsers] = useState<IOption[]>([])

  const handleModalClose = () => {
    onClose()
  }

  const handleSaveProperty = async () => {
    await onSave(selectedUsers, cra.id)
    setSelectedUsers([])
  }

  return (
    <RecallModal
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={handleSaveProperty}
      onClose={handleModalClose}
      disabled={!selectedUsers.length}
      size={fullScreen ? 'medium' : 'small'}
      labelYes='Share'
      loadingYes={loading}
    >
      <div className={Style.contentWrapper}>
        <h2>Manage Access</h2>
        <p className={Style.propertyDescription}>You can manage access on this Custom Recall app</p>
        <Grid row gap={12}>
          <UserComboBox
            onSelectedItem={setSelectedUsers}
            selectedItems={selectedUsers}
            excludeIds={[cra.ownerId]}
            label='Select User'
            placeholder='Enter user email address...'
          />

          <Grid item xs={12}>
            <Table
              headers={[
                {
                  label: 'Name',
                },
                {
                  label: 'Shared on',
                },

                { label: 'Action' },
              ]}
              cssClass={Style.manageAccessTable}
            >
              {customAppShareList.map((c) => (
                <TableRow key={c.auth0UserId}>
                  <TableCell cellClass={Style.cell}>{c.name}</TableCell>
                  <TableCell cellClass={Style.cell}> {format(parseISO(c.updatedAt), twelveHourFormat)}</TableCell>
                  <TableCell cellClass={Style.cell}>
                    <Icon type='person_remove' />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Grid>
        </Grid>
      </div>
    </RecallModal>
  )
}

export default ManageAccessModal
