import { FC, useState } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import { NomicDataSourceEnum } from '../../enums/BambooPropertyEnum'
import LimitedCharactersLeft from '../common/LimitedCharacters'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallFormInput from '../common/RecallFormInput'
import RecallDropdown from '../common/RecallDropdown'
import { v4 } from 'uuid'
import { getErrorsFromValidationResult, IErrorModel } from '../../validators/commonValidator'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'

import Style from '../../styles/NomicModal.module.sass'
import { INomicModel } from '../../models/INomicModel'
import { NomicPropertyModalFields, nomicPropertyModalSchema } from '../../validators/nomicPropertyModalValidator'
import { DefaultNomicProperty } from '../../pages/CustomRecallAppHome'

import { ReactComponent as NomicImageLeft } from '../../assets/nomic_1.svg'
import { ReactComponent as NomicImageRight } from '../../assets/nomic_2.svg'

interface INomicModalProps {
  open: boolean
  nomicPropertyModel: INomicModel
  onSave: (data: INomicModel) => Promise<void>
  onClose: () => void
  loading?: boolean
}

const NomicModal: FC<INomicModalProps> = (props) => {
  const { open, nomicPropertyModel, onSave, onClose, loading } = props
  const [nomicProperty, setNomicProperty] = useState<INomicModel>({
    ...nomicPropertyModel,
    dataSource: NomicDataSourceEnum.EXISTING_NOMIC,
  })
  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)

  const handleModalClose = () => {
    setNomicProperty(DefaultNomicProperty)
    setErrors({})
    onClose()
  }

  const validateNomicProperty = (currentProperty: INomicModel) => {
    const validationResult = nomicPropertyModalSchema().validate(
      { dataSource: currentProperty.dataSource, appName: currentProperty.appName, nomicMap: currentProperty.nomicMap },
      { abortEarly: false },
    )
    return getErrorsFromValidationResult(validationResult)
  }

  const handleValueChange = (field: string, value: string | number, limit?: number) => {
    const val = typeof value === 'string' ? value.substring(0, limit) : value
    const updatedProperty = { ...nomicProperty, [field]: val }
    setNomicProperty(updatedProperty)
    const errors = validateNomicProperty(updatedProperty)
    setErrors(errors)
  }

  const handleSaveProperty = async () => {
    const errors = validateNomicProperty(nomicProperty)
    if (Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    await onSave({
      ...nomicProperty,
      id: nomicProperty.id || v4(),
    })

    handleModalClose()
  }

  return (
    <RecallModal
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={handleSaveProperty}
      onClose={handleModalClose}
      disabled={!!Object.keys(errors).length || !nomicProperty.appName || !nomicProperty.dataSource}
      size={fullScreen ? 'medium' : 'small'}
      labelYes={nomicProperty.id ? 'Update' : 'Create'}
      loadingYes={loading}
    >
      <div className={Style.contentWrapper}>
        <h2>{nomicProperty.id ? 'Update' : 'Create'}</h2>
        <p className={Style.propertyDescription}>
          All aurecon employees can use Nomic to visualise and organize their information here:{' '}
          <a href='https://aurecon-atlas.nomic.ai/' target='_blank' rel='noopener noreferrer'>
            Nomic Atlas
          </a>
        </p>
        <Grid row gap={12}>
          <Grid item xs={12}>
            <RecallDropdown
              open='down'
              label='Type'
              items={[{ id: NomicDataSourceEnum.EXISTING_NOMIC, label: 'Use existing Nomic map' }]}
              selectedItem={nomicProperty.dataSource}
              onSelectItem={(val) => {
                handleValueChange(NomicPropertyModalFields.dataSource, val)
              }}
              cssClass={Style.type}
              placeholder='Select an option'
              chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            />
            {errors.dataSource?.[0] && <div>{errors.dataSource[0]}</div>}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder='Enter your app name...'
              value={nomicProperty.appName}
              onChange={(val) => handleValueChange(NomicPropertyModalFields.appName, val, 200)}
              cssClass={Style.description}
              label='App Name'
              error={errors.appName?.[0]}
              chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            />
            {!errors.appName?.[0] && <LimitedCharactersLeft maxLength={200} value={nomicProperty.appName} />}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder='Enter the nomic map URL'
              value={nomicProperty.nomicMap}
              onChange={(val) => handleValueChange(NomicPropertyModalFields.nomicMap, val)}
              cssClass={Style.description}
              label='Nomic Map'
              chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
              error={errors.nomicMap?.[0]}
            />
          </Grid>
          <Grid item xs={12} cssClass={Style.infoRow}>
            <Icon type='lightbulb' cssClass={Style.infoIcon} outlined />
            <span>
              Navigate to the map, press the <b>"Share"</b> button, then press the <b>"Copy Link"</b> button.
            </span>
          </Grid>
          <Grid item xs={12} cssClass={Style.instructions}>
            <NomicImageLeft />
            <Icon type='arrow_circle_right' outlined={true} />
            <NomicImageRight />
          </Grid>
        </Grid>
      </div>
    </RecallModal>
  )
}

export default NomicModal
