import { FC, SVGProps } from 'react'
import { Pill } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'

import Style from '../styles/AppCard.module.sass'
import { useLanguages } from '../hooks/useLanguages'

interface IAppCardProps {
  appName: string
  AppIcon: FC<SVGProps<SVGSVGElement>>
  appContents: JSX.Element
  appButtonLabel: string
  buttonColour: string
  experimental?: boolean
  inactive?: boolean
  onClick: () => void
}

const INACTIVE_COLOUR = 'lightgray'
const INACTIVE_TEXT = 'Coming Soon'

const AppCard: FC<IAppCardProps> = (props) => {
  const { appName, AppIcon, appContents, appButtonLabel, buttonColour, experimental, inactive, onClick } = props
  const { t } = useLanguages()

  const cardClasses = classNames({
    [Style.chatCard]: true,
    [Style.inactive]: inactive,
  })

  const contentClasses = classNames({
    [Style.content]: true,
    [Style.experimental]: experimental,
    [Style.inactive]: inactive,
  })

  return (
    <div className={cardClasses} onClick={inactive ? undefined : onClick} role='none'>
      <div className={contentClasses}>
        <h1>
          <AppIcon role='image' aria-label={appName} className={Style.logo} /> {appName}
          <br />
          {experimental && (
            <Pill colour={13} icon='science'>
              {t('experimental_tag')}
            </Pill>
          )}
        </h1>
        {appContents}
      </div>
      <div className={Style.accent} style={{ backgroundColor: inactive ? INACTIVE_COLOUR : buttonColour }}>
        {inactive ? INACTIVE_TEXT : appButtonLabel}
      </div>
    </div>
  )
}

export default AppCard
