import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Button, Tooltip, Loader } from '@aurecon-creative-technologies/styleguide'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { ChatSession, ChatType, NomicSession, NotFound, QuestionFile, ScrollChat } from '../stores/AppStore'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import Style from '../styles/Home.module.sass'
import ChatSwitcher from '../components/ChatSwitcher'
import { useParams } from 'react-router-dom'
import { getCustomApp } from '../api/CustomRecallAppService'
import ChatNotFound from '../components/ChatNotFound'
import { ResponseData } from '../models/api/IResponse'
import FileDownloadModal from '../components/modals/FileDownloadModal'
import ChatAnswer from '../components/ChatAnswer'
import ChatQuestion from '../components/ChatQuestion'
import { getChatQuestions } from '../api/QuestionService'

const ChatCra: FC = () => {
  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const { t, i18n } = useLanguages()
  const [loading, setLoading] = useState(false)
  const [notFound, setNotFound] = useRecoilState(NotFound)
  const [nomicSession, setNomicSession] = useRecoilState(NomicSession)
  const contentsRef = useRef<HTMLDivElement>(null)
  const setScrollChat = useSetRecoilState(ScrollChat)
  const [chatSession, setChatSession] = useRecoilState(ChatSession)

  const { customAppId, chatId } = useParams()

  useEffect(() => {
    if (chatSession && chatSession.type === ChatTypeEnum.CUSTOM_RECALL_APP) return
    setChatSession(null)
  }, [setChatSession, chatSession])

  useEffect(() => {
    setChatType(ChatTypeEnum.CUSTOM_RECALL_APP)
    setQuestionFile(null)

    return () => setChatType(null)
  }, [setChatType, setQuestionFile])

  const updateScroll = useCallback(() => {
    setTimeout(() => {
      setScrollChat((s) => s + 1)
    }, 1000)
  }, [setScrollChat])

  useEffect(() => {
    const getSessionAsync = async () => {
      const id = customAppId ?? ''
      const customApp = ResponseData(await getCustomApp({ id }))

      if (!customApp) {
        setLoading(false)
        setNotFound(true)
        setNomicSession(null)
        setChatType(null)
        return
      }

      setChatType(ChatTypeEnum.CUSTOM_RECALL_APP)
      setNomicSession(customApp)
      setLoading(false)
      setNotFound(false)

      updateScroll()
    }

    if (nomicSession?.id === customAppId) return

    setLoading(true)
    getSessionAsync()
  }, [customAppId, nomicSession?.id, setChatType, setNomicSession, setNotFound, setScrollChat, updateScroll])

  useEffect(() => {
    const getSessionAsync = async () => {
      const id = chatId ?? ''
      const questions = await getChatQuestions({ chatId: id })

      if (!questions?.data) {
        setLoading(false)
        setNotFound(true)
        setChatSession(null)
        setNomicSession(null)
        return
      }

      setChatSession({
        chatId: id,
        questions: questions.data,
        type: ChatTypeEnum.CUSTOM_RECALL_APP,
      })

      setLoading(false)
      setNotFound(false)

      updateScroll()
    }

    if (nomicSession?.id !== customAppId) return
    if (chatSession?.chatId === chatId) return

    setLoading(true)
    getSessionAsync()
  }, [
    chatId,
    chatSession?.chatId,
    customAppId,
    nomicSession?.id,
    setChatSession,
    setNomicSession,
    setNotFound,
    setScrollChat,
    updateScroll,
  ])

  if (appInsights) appInsights.trackPageView({ name: 'Custom Recall Chat Home' })

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  if (loading)
    return (
      <Page menu contentWrapper>
        <Loader label='Loading custom recall app...' />
      </Page>
    )

  return (
    <Page menu contentWrapper contentsRef={contentsRef}>
      <h1 className={Style.chatTitle} title={nomicSession?.name}>
        {nomicSession?.name}
      </h1>
      <div className={Style.craAppSwitcherContainer}>
        <Tooltip show='Open Nomic Map' cssClass={Style.nomicMapLinkTooltip}>
          <Button
            cssClass={Style.mapLinkButton}
            onClick={() => window.open(nomicSession?.mapUrl, '_blank')}
            type='icon-round'
            icon='link'
          />
        </Tooltip>
      </div>
      <ChatSwitcher activeChat={ChatTypeEnum.CUSTOM_RECALL_APP} customSelectedCraLabel={nomicSession?.name} />
      <div className={Style.chatContents}>
        {loading && <Loader label='Loading chat...' />}
        {!loading && notFound && <ChatNotFound />}
        {!loading &&
          chatSession?.questions.map((question) => {
            return (
              <div key={question.rowKey}>
                <ChatQuestion question={question} />
                <ChatAnswer question={question} />
              </div>
            )
          })}
      </div>
      <FileDownloadModal />
    </Page>
  )
}

export default ChatCra
