import { FC, useEffect, useRef, useState, useMemo } from 'react'
import Style from '../styles/ChatSwitcher.module.sass'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import { useChangeChat } from '../hooks/useChangeChat'
import { ChatTypeEnum, ChatTypeToLabel } from '../enums/ChatTypeEnum'
import { useRecoilValueLoadable } from 'recoil'
import { CustomAppsLoader } from '../stores/AppStore'
import { useParams } from 'react-router-dom'
import { CRATypeEnum } from '../enums/CRATypeEnum'

export interface IChatSwitcherProps {
  activeChat: number
  customSelectedCraLabel?: string
}
interface IChatConfig {
  style: string
  label: string
}

interface IChatItemConfig {
  type: number
  label: string
  id?: string
}

const ChatSwitcher: FC<IChatSwitcherProps> = ({ activeChat, customSelectedCraLabel }) => {
  const { openRecallChat, openChatGPT, openWinWise, openBamboo, openCustomRecallChat } = useChangeChat()
  const containerRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)
  const [customChatItems, setCustomChatItems] = useState<IChatItemConfig[] | null>(null)
  const customAppsLoader = useRecoilValueLoadable(CustomAppsLoader)
  const { customAppId } = useParams()

  useEffect(() => {
    if (customAppsLoader.state !== 'hasValue' || !customAppsLoader.contents) return
    setCustomChatItems(
      customAppsLoader.contents
        .filter((c) => c.status === CRATypeEnum.PUBLISHED)
        .map((c) => ({ type: ChatTypeEnum.CUSTOM_RECALL_APP, label: c.name, id: c.id })),
    )
  }, [customAppsLoader.contents, customAppsLoader.state])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as Node)) {
        setExpanded(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerRef])

  const chatItems: IChatItemConfig[] = useMemo(() => {
    return [
      { type: ChatTypeEnum.RECALL, label: ChatTypeToLabel[ChatTypeEnum.RECALL].label },
      { type: ChatTypeEnum.GPT, label: ChatTypeToLabel[ChatTypeEnum.GPT].label },
      { type: ChatTypeEnum.WINWISE, label: ChatTypeToLabel[ChatTypeEnum.WINWISE].label },
      { type: ChatTypeEnum.BAMBOO, label: ChatTypeToLabel[ChatTypeEnum.BAMBOO].label },
      ...(customChatItems || []),
    ]
  }, [customChatItems])

  const onClick = (openChat: number, id?: string) => {
    switch (openChat) {
      case ChatTypeEnum.RECALL: {
        openRecallChat()
        break
      }
      case ChatTypeEnum.GPT: {
        openChatGPT()
        break
      }
      case ChatTypeEnum.WINWISE: {
        openWinWise(true)
        break
      }
      case ChatTypeEnum.BAMBOO: {
        openBamboo()
        break
      }
      case ChatTypeEnum.CUSTOM_RECALL_APP: {
        openCustomRecallChat(id)
        break
      }
    }
  }

  const chosenCRA = useMemo(() => {
    return customChatItems?.find((c) => c.id === customAppId)
  }, [customAppId, customChatItems])

  const chatConfig = useMemo(() => {
    let config: IChatConfig | null = null

    switch (activeChat) {
      case ChatTypeEnum.RECALL: {
        config = {
          style: Style.recall,
          label: ChatTypeToLabel[ChatTypeEnum.RECALL].label,
        }
        break
      }
      case ChatTypeEnum.GPT: {
        config = {
          style: Style.chatGpt,
          label: ChatTypeToLabel[ChatTypeEnum.GPT].label,
        }
        break
      }
      case ChatTypeEnum.WINWISE: {
        config = {
          style: Style.winwise,
          label: ChatTypeToLabel[ChatTypeEnum.WINWISE].label,
        }
        break
      }
      case ChatTypeEnum.BAMBOO: {
        config = {
          style: Style.bamboo,
          label: ChatTypeToLabel[ChatTypeEnum.BAMBOO].label,
        }
        break
      }
      case ChatTypeEnum.CUSTOM_RECALL_APP: {
        config = {
          style: Style.cra,
          label: customSelectedCraLabel ?? chosenCRA?.label ?? ChatTypeToLabel[ChatTypeEnum.CUSTOM_RECALL_APP].label,
        }
        break
      }
    }

    return config
  }, [activeChat, chosenCRA?.label, customSelectedCraLabel])

  const chatItemClasses = (type: number, id?: string) => {
    return classNames({
      [Style.item]: true,
      [Style.active]:
        type !== ChatTypeEnum.CUSTOM_RECALL_APP ? activeChat === type : activeChat === type && id === chosenCRA?.id,
    })
  }

  return (
    <div className={Style.chatSwitcherContainer} ref={containerRef}>
      <div className={classNames(Style.selectedChat, chatConfig?.style)}>
        <div role='none' className={Style.label} onClick={() => setExpanded(!expanded)}>
          <div className={classNames(Style.icon, chatConfig?.style)}></div>
          <span title={chatConfig?.label} className={Style.topLabel}>
            {chatConfig?.label}
          </span>
        </div>
        <div className={Style.expandIcon}>
          <Icon type='expand_more' onClick={() => setExpanded(!expanded)} />
        </div>
      </div>

      {expanded && (
        <div className={Style.chatItemsContainer}>
          <div className={Style.chatItems}>
            {chatItems.map((m) => {
              return (
                <div
                  role='none'
                  key={m.type}
                  title={m.label}
                  className={chatItemClasses(m.type, m.id)}
                  onClick={() => onClick(m.type, m.id)}
                >
                  {m.label}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatSwitcher
