import { createAuth0Client } from '@auth0/auth0-spa-js'
import jwt_decode from 'jwt-decode'
import config, { USER_OID } from '../config/config'
import { IAppConfig } from './AppService'

export const EmailScope = 'email'

interface IAccessToken {
  ['https://aurecon.com/email']: string
  ['https://aurecon.com/name']: string
  ['https://aurecongroup.com/roles']: string[]
  ['https://aurecongroup.com/user_metadata']: string
  ['https://aurecon.com/oid']: string
  ['iss']: string
  ['sub']: string
  ['aud']: string[]
  ['iat']: string
  ['exp']: string
  ['scope']: string
  ['azp']: string
}
export interface IAPIRequest<T, U> {
  (props: T): Promise<U>
}

// eslint-disable-next-line
let tokenMgr: any = null

// eslint-disable-next-line
export function TokenManager() {
  if (tokenMgr) return tokenMgr

  const EMPTY_STRING_ARRAY: string[] = []
  const NO_TOKEN = ''

  const token = {
    jwt: NO_TOKEN,
    scopes: EMPTY_STRING_ARRAY,
    expired: false,
  }

  tokenMgr = new Promise((resolve) => {
    createAuth0Client({
      domain: config.AUTH0_DOMAIN,
      clientId: config.AUTH0_CLIENT_ID,
    }).then((auth0) => {
      async function tokenRequest(scopes: string[]) {
        const scope = ['read:current_user', ...scopes].join(' ')

        try {
          return await auth0.getTokenSilently({
            authorizationParams: {
              audience: config.AUTH0_AUDIENCE,
              scope: scope,
            },
          })

          // eslint-disable-next-line
        } catch (error: any) {
          if (error.error === 'consent_required') {
            return 'consent_required'
          } else if (error.error === 'login_required') {
            console.log(`Error ${error.error}`)
            return 'login_required'
          } else {
            throw new Error(`Unknown error: ${error.error}`)
          }
        }
      }

      function requestToken(scopes: string[]): Promise<string> {
        return new Promise((resolve2) => {
          const jwtToken = token.jwt
          if (!jwtToken || token.expired) {
            tokenRequest(scopes).then((newToken) => {
              token.jwt = newToken
              token.scopes = scopes
              resolve2(token.jwt)
            })
          } else {
            resolve2(token.jwt)
          }
        })
      }

      function initiateConsentPopup(): Promise<string | undefined> {
        return auth0.getTokenWithPopup({
          authorizationParams: {
            audience: config.AUTH0_AUDIENCE,
            scope: '',
          },
        })
      }

      function handleTokenExpiry() {
        token.expired = true
      }

      function checkTokenExpiration(tokenString: string) {
        try {
          //Here we are checking for expiry token which is one possibility when 401 occurs
          const decoded = jwt_decode<IAccessToken>(tokenString)
          const now = Date.now() / 1000
          const expiryString = decoded ? decoded['exp'] : null
          const expiryEpoch = expiryString ? parseInt(expiryString) : now

          return expiryEpoch < now
        } catch (error) {
          console.error("** Can't decode token...")
          console.error(error)
          return false
        }
      }

      function getUserOID() {
        try {
          const decoded = jwt_decode<IAccessToken>(token.jwt)
          return decoded[USER_OID]
        } catch (error) {
          return 'error'
        }
      }

      async function getNomicToken(scopes: string[], nomic_config: IAppConfig) {
        try {
          console.log('** Creating client...')
          const airAuth0Client = await createAuth0Client({
            domain: nomic_config.NOMIC_AUTH0_DOMAIN,
            clientId: nomic_config.NOMIC_AUTH0_CLIENT_ID,
            issuer: nomic_config.NOMIC_AUTH0_ISSUER,
          })

          console.log('** Getting token...')
          const scope = ['read:current_user', ...scopes].join(' ')

          const token = await airAuth0Client.getTokenSilently({
            authorizationParams: {
              audience: nomic_config.NOMIC_AUTH0_AUDIENCE,
              scope,
            },
          })

          return token
        } catch (err) {
          console.log(err)
          return null
        }
      }

      tokenMgr = {
        requestToken,
        initiateConsentPopup,
        handleTokenExpiry,
        checkTokenExpiration,
        getUserOID,
        getNomicToken,
      }

      resolve(tokenMgr)
    })
  })
  return tokenMgr
}

export function TokenExpiryWrapper<T, U>(
  apiCall: IAPIRequest<T, U>,
  scopes: string[],
  errorReturnValue: U,
): IAPIRequest<T, U> {
  return async (props: T) => {
    const tokenManager = await TokenManager()
    let token = await tokenManager.requestToken(scopes)
    let expired = tokenManager.checkTokenExpiration(token)

    if (expired) {
      try {
        tokenMgr.handleTokenExpiry()
        token = await tokenMgr.requestToken(scopes)
      } catch (err2) {
        return errorReturnValue
      }
    }

    try {
      return await apiCall({ token, ...props })

      // eslint-disable-next-line
    } catch (error: any) {
      if (error.status === 401) {
        expired = tokenManager.checkTokenExpiration(token)
        if (expired) {
          try {
            tokenMgr.handleTokenExpiry()
            token = await tokenMgr.requestToken(scopes)
            return await apiCall({ token, ...props })
          } catch (err2) {
            return errorReturnValue
          }
        } else {
          console.log('Not authorized to access resource')
          return errorReturnValue
        }
      } else if (error.status === 500) {
        return errorReturnValue
      } else {
        console.log('handle error response which probably should be considered')
        console.log(error)
        return errorReturnValue
      }
    }
  }
}
