import { Icon, Button, InfoTooltip, sizeUnits } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/Bamboo.module.sass'
import PDFViewer from '../components/PDFViewer'
import { useLanguages } from '../hooks/useLanguages'
import { Trans } from 'react-i18next'

//File Upload Component Properties
export const dropzoneHeight = '400px'
export const dropzoneWidth = '100%'
export const sizeLimit = 20
export const fileUnits = sizeUnits.MegaByte
export const fileTypes = ['.pdf']

export const useAllowedFileExtensionsMessage = () => {
  const { t } = useLanguages()
  return t('bamboo_err5', { fileTypes: fileTypes.join(', ') })
}

export const useSizeLimitMessage = () => {
  const { t } = useLanguages()
  return t('bamboo_err4', { size: sizeLimit, unit: fileUnits.label })
}

export const DropzoneTooltip = () => {
  const dropzoneFileTypes = fileTypes?.map((type) => type.replace('.', '').toUpperCase()).join(', ')
  const dropzoneSizeLimit = `${sizeLimit}${fileUnits.label.toLowerCase()}`
  const { t } = useLanguages()

  return (
    <span className='tooltip'>
      <InfoTooltip
        show={
          <span style={{ textAlign: 'left' }}>
            <div>{t('upload_title2_tolltip')}</div>
            <div>{fileTypes?.join(', ')}</div>
          </span>
        }
      />
      {t('upload_title2', { fileTypes: dropzoneFileTypes, maxSize: dropzoneSizeLimit })}
    </span>
  )
}

export const DropzoneLabel = () => {
  return (
    <div className={Style.dropzoneLabelContainer}>
      <div className={Style.dropzoneLabelIconContainer}>
        <Icon size={'36px'} type='picture_as_pdf' outlined cssClass='fileUploadIcon' />
      </div>
      <div className={Style.dropzoneLabelTextContainer}>
        <Trans i18nKey='upload_title' components={[<span key={0} className='browse' />]} />
      </div>
    </div>
  )
}

export const fileActionRenderer = (file: File, removeFile: (file: File) => void) => (
  <div className='file-uploader-delete-container'>
    <Button
      icon='delete'
      size='extra small'
      type='icon-round'
      cssClass='file-uploader-delete'
      onClick={() => removeFile(file)}
    />
  </div>
)

export const renderPDFViewer = (file: File) => {
  return (
    <PDFViewer
      file={URL.createObjectURL(file)}
      showOnlyAsPreview={false}
      height={398}
      viewerId={`viewer-${file.name}`}
    />
  )
}
