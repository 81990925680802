import { ATTACHMENT_EXT, MAX_ATTACHMENT_SIZE, MAX_NO_ATTACHMENTS } from '../config/config'
import { AppColour } from './AppColourConstants'

export const ChatTypeEnum = {
  RECALL: 0,
  GPT: 1,
  CODE: 2,
  WINWISE: 4,
  BAMBOO: 5,
  CUSTOM_RECALL_APP: 6,
}

export const ChatTypeToPath = {
  [ChatTypeEnum.RECALL]: 'chat-recall',
  [ChatTypeEnum.GPT]: 'chat-gpt',
  [ChatTypeEnum.CODE]: 'chat-code',
  [ChatTypeEnum.BAMBOO]: 'chat-bamboo',
  [ChatTypeEnum.CUSTOM_RECALL_APP]: 'chat-custom-recall-app',
}

export const ChatTypeToLabel = {
  [ChatTypeEnum.RECALL]: { label: 'Recall', desktop: 'Recall', mobile: 'R', colour: AppColour.RECALL_CHAT },
  [ChatTypeEnum.GPT]: { label: 'Secure ChatGPT', desktop: 'GPT', mobile: 'G', colour: AppColour.GPT_CHAT },
  [ChatTypeEnum.CODE]: { label: 'Code Assistant', desktop: 'Code', mobile: 'C', colour: AppColour.CODE_CHAT },
  [ChatTypeEnum.WINWISE]: { label: 'Winwise', desktop: 'Winwise', mobile: 'W', colour: AppColour.WINWISE },
  [ChatTypeEnum.BAMBOO]: { label: 'Bamboo', desktop: 'Bamboo', mobile: 'B', colour: AppColour.BAMBOO },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: {
    label: 'Custom Recall App',
    desktop: 'Custom Recall',
    mobile: 'C',
    colour: AppColour.CUSTOM_RECALL_APP,
  },
}

export const ChatTypeStream = {
  [ChatTypeEnum.RECALL]: true,
  [ChatTypeEnum.GPT]: true,
  [ChatTypeEnum.CODE]: false,
  [ChatTypeEnum.CUSTOM_RECALL_APP]: true,
}

export const ChatTypeActions = {
  loading: { clipboard: false, info: true, rating: false, feedback: false, edit: false },
  [ChatTypeEnum.RECALL]: { clipboard: true, info: true, rating: true, feedback: true, edit: true },
  [ChatTypeEnum.GPT]: { clipboard: true, info: true, rating: false, feedback: false, edit: false },
  [ChatTypeEnum.CODE]: { clipboard: true, info: true, rating: true, feedback: false, edit: false },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: { clipboard: true, info: true, rating: true, feedback: true, edit: true },
}

export const ChatTypeInput = {
  [ChatTypeEnum.RECALL]: { focus: false, file: false, fileDrop: false },
  [ChatTypeEnum.GPT]: { focus: false, file: true, fileDrop: true },
  [ChatTypeEnum.CODE]: { focus: false, file: false, fileDrop: false },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: { focus: false, file: false, fileDrop: false },
}

export const ChatTypeConfig = {
  [ChatTypeEnum.RECALL]: { maxSize: 0, maxFiles: 0, extensions: [] },
  [ChatTypeEnum.GPT]: { maxSize: MAX_ATTACHMENT_SIZE, maxFiles: MAX_NO_ATTACHMENTS, extensions: ATTACHMENT_EXT },
  [ChatTypeEnum.CODE]: { maxSize: 0, maxFiles: 0, extensions: [] },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: { maxSize: 0, maxFiles: 0, extensions: [] },
}

export const ChatTypeToPrompt = {
  [ChatTypeEnum.RECALL]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.GPT]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following2',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.CODE]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
}
