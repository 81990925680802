import { FC } from 'react'

import { ReactComponent as RecallMenuIcon } from '../../src/assets/Recall_menu_icon.svg'
import { ReactComponent as GPTMenuIcon } from '../../src/assets/ChatGPT_menu_icon.svg'
import { ReactComponent as WinWiseLogo } from '../../src/assets/WinWise_logo.svg'
import { ReactComponent as CRALogo } from '../../src/assets/CRA_logo.svg'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useMediaQuery } from 'react-responsive'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import { AppTile } from '../components/AppTile'
import { useChangeChat } from '../hooks/useChangeChat'
import { AppPill } from '../components/AppPill'
import { AppColour } from '../enums/AppColourConstants'
import { AppShortcut } from '../components/AppShortcut'
import { UI_WIDTH_COLLAPSE } from '../config/config'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useShowFeature } from '../hooks/useShowFeature'

import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/skyblue'
import '@splidejs/react-splide/css/sea-green'
import '@splidejs/react-splide/css/core'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import Style from '../styles/Home.module.sass'

const Home: FC = () => {
  const { openRecallChat, openChatGPT, openWinWise, openBamboo, openCustomRecallApp } = useChangeChat()
  const showBambooChat = useShowFeature(FeatureFlagEnum.ShowBambooChat)
  const showCustomRecallChat = useShowFeature(FeatureFlagEnum.ShowCustomRecallApp)

  const isMobile = useMediaQuery({ maxWidth: UI_WIDTH_COLLAPSE })

  const { t, i18n } = useLanguages()

  if (appInsights) appInsights.trackPageView({ name: 'Home' })

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  return (
    <Page menu contentWrapper>
      <h1>{t('home_header')}</h1>

      {isMobile ? (
        <div className={Style.tiles}>
          <Splide aria-label='Chat Types' options={{ arrows: false, autoplay: true, loop: true, rewind: true }}>
            <SplideSlide>
              <AppTile AppIcon={RecallMenuIcon} content={t('recall_header')} onClick={openRecallChat} />
            </SplideSlide>
            <SplideSlide>
              <AppTile AppIcon={GPTMenuIcon} content={t('securegpt_header')} onClick={openChatGPT} />
            </SplideSlide>
            <SplideSlide>
              <AppTile AppIcon={WinWiseLogo} content={t('winwise_header')} onClick={openWinWise} />
            </SplideSlide>
            {showCustomRecallChat.enabled && (
              <SplideSlide>
                <AppTile
                  AppIcon={CRALogo}
                  content={
                    'Upload a file and have it find any reference to a certain topic. Pull out relevant quotes from a document.'
                  }
                  onClick={openCustomRecallApp}
                />
              </SplideSlide>
            )}
          </Splide>
        </div>
      ) : (
        <div className={Style.tiles}>
          <AppTile AppIcon={RecallMenuIcon} content={t('recall_header')} onClick={openRecallChat} />
          <AppTile AppIcon={GPTMenuIcon} content={t('securegpt_header')} onClick={openChatGPT} />
          <AppTile AppIcon={WinWiseLogo} content={t('winwise_header')} onClick={openWinWise} />
          {showCustomRecallChat.enabled && (
            <AppTile
              AppIcon={CRALogo}
              content={
                'Upload a file and have it find any reference to a certain topic. Pull out relevant quotes from a document.'
              }
              onClick={openCustomRecallApp}
            />
          )}
        </div>
      )}

      <h1>{t('home_header2')}</h1>

      <div className={Style.pills}>
        <AppPill content={t('recall_pin')} onClick={openRecallChat} colour={AppColour.RECALL_CHAT} />
        <AppPill content={t('securegpt_pin')} onClick={openChatGPT} colour={AppColour.GPT_CHAT} />
        <AppPill content={t('winwise_pin')} onClick={openWinWise} colour={AppColour.WINWISE} />
        {showBambooChat.enabled && <AppPill content={t('bamboo_pin')} onClick={openBamboo} colour={AppColour.BAMBOO} />}
        {showCustomRecallChat.enabled && (
          <AppPill
            content='Processes requests from a document'
            onClick={openCustomRecallApp}
            colour={AppColour.CUSTOM_RECALL_APP}
          />
        )}
      </div>

      <hr className={Style.divider} />

      <h2>{t('home_header3')}</h2>
      <div className={Style.shortcuts}>
        <AppShortcut AppIcon={RecallMenuIcon} content='Recall Chat' onClick={openRecallChat} />
        <AppShortcut AppIcon={GPTMenuIcon} content='Secure ChatGPT' onClick={openChatGPT} />
        <AppShortcut AppIcon={WinWiseLogo} content='WinWise' onClick={openWinWise} />
        {showCustomRecallChat.enabled && (
          <AppShortcut AppIcon={CRALogo} content='Custom Recall App' onClick={openCustomRecallApp} />
        )}
      </div>
    </Page>
  )
}

export default Home
