import { FC, useMemo } from 'react'
import classNames from 'classnames'
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Icon, ThemeSwitcher } from '@aurecon-creative-technologies/styleguide'
import { useAuth0 } from '@auth0/auth0-react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import { ReactComponent as RecallLogo } from '../../src/assets/Recall_logo.svg'
import { ReactComponent as RecallLogoSmall } from '../../src/assets/Recall_logo_small.svg'

import config, { UI_WIDTH_COLLAPSE, USER_GUIDE_URL } from '../config/config'
import { AppVersion, LoadingAnswer, ShowExtendedInput, ChatSession, NomicSession } from '../stores/AppStore'
import { useUserHasPermissions } from '../hooks/useUserHasPermissions'
import { actions } from '../config/permissions'
import { AppRoute } from '../enums/AppRouteConstants'
import FullScreenSwitch from './FullScreenSwitch'
import { useLanguages } from '../hooks/useLanguages'
import LanguageSwitch from './LanguageSwitch'
import { IAppVersion } from '../api/AppService'

import Style from '../styles/Menu.module.sass'

const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`

const Menu: FC = () => {
  const appVersion = useRecoilValueLoadable(AppVersion)
  const setChatSession = useSetRecoilState(ChatSession)
  const setNomicSession = useSetRecoilState(NomicSession)
  const setShowExtendedInput = useSetRecoilState(ShowExtendedInput)
  const loadingAnswer = useRecoilValue(LoadingAnswer)
  const { logout } = useAuth0()
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const loc = useLocation()
  const { t } = useLanguages()

  const canAccessDashboard = useUserHasPermissions(actions.VIEW_DASHBOARD)

  const version = useMemo(() => {
    if (appVersion.state !== 'hasValue') return '0.0.0'

    return (appVersion.contents as IAppVersion).version
  }, [appVersion])

  const handleChatHistory = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)

    setShowExtendedInput(false)
    location.hash = `#/${AppRoute.HISTORY}`
  }

  const handleUserGuide = () => {
    if (loadingAnswer) return
    window.open(USER_GUIDE_URL, '_blank')
  }

  const handleDashboard = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)

    setShowExtendedInput(false)
    location.hash = `#/${AppRoute.DASHBOARD}`
  }

  const handleApps = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)

    setShowExtendedInput(false)
    location.hash = `#/${AppRoute.APPS}`
  }

  const handleHome = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setNomicSession(null)

    setShowExtendedInput(false)
    location.hash = `#/`
  }

  const handleLogout = () => {
    setChatSession(null)
    setNomicSession(null)

    logout({ returnTo })
  }

  const menuClasses = classNames({
    [Style.menu]: true,
    [Style.collapsed]: !isDesktop,
  })

  const homeClasses = classNames({
    [Style.menuItem]: true,
    [Style.disabled]: loadingAnswer,
    [Style.active]:
      loc.pathname === '/' ||
      loc.pathname === '/#' ||
      loc.pathname.includes(`/${AppRoute.RECALL_CHAT}`) ||
      loc.pathname.includes(`/${AppRoute.GPT_CHAT}`),
  })

  const appsClasses = classNames({
    [Style.menuItem]: true,
    [Style.disabled]: loadingAnswer,
    [Style.active]: loc.pathname.includes(`/${AppRoute.APPS}`) || loc.pathname.includes(`/${AppRoute.CODE_CHAT}`),
  })

  const historyClasses = classNames({
    [Style.menuItem]: true,
    [Style.disabled]: loadingAnswer,
    [Style.active]: loc.pathname.includes(`/${AppRoute.HISTORY}`),
  })

  const userGuideClasses = classNames({
    [Style.menuItem]: true,
    [Style.disabled]: loadingAnswer,
    [Style.active]: loc.pathname.includes(`/${AppRoute.USER_GUIDE}`),
  })

  const dashboardClasses = classNames({
    [Style.menuItem]: true,
    [Style.disabled]: loadingAnswer,
    [Style.active]: loc.pathname.includes(`/${AppRoute.DASHBOARD}`),
  })

  return (
    <div className={menuClasses}>
      {isDesktop ? <RecallLogo className={Style.recallLogo} /> : <RecallLogoSmall className={Style.recallLogo} />}
      <div className={homeClasses} onClick={handleHome} role='none'>
        <Icon type='home' outlined />
        {isDesktop && t('home')}
      </div>

      <div className={appsClasses} onClick={handleApps} role='none'>
        <Icon type='dashboard' outlined />
        {isDesktop && t('apps')}
      </div>

      <div className={historyClasses} onClick={handleChatHistory} role='none'>
        <Icon type='history' outlined />
        {isDesktop && t('history')}
      </div>

      <div className={userGuideClasses} onClick={handleUserGuide} role='none'>
        <Icon type='help_outline' outlined />
        {isDesktop && t('user_guide')}
      </div>

      {canAccessDashboard && (
        <div className={dashboardClasses} onClick={handleDashboard} role='none'>
          <Icon type='analytics' outlined />
          {isDesktop && t('dashboard')}
        </div>
      )}

      <div className={Style.menuItem} onClick={handleLogout} role='none'>
        <Icon type='logout' />
        {isDesktop && t('log_out')}
      </div>

      <div className={Style.menuFooter}>
        <div className={Style.themeSwitcherContainer}>
          <FullScreenSwitch />
          <ThemeSwitcher
            size={isDesktop ? 'desktop' : 'mobile'}
            {...(isDesktop && { labelLight: t('light_switch'), labelDark: t('dark_switch') })}
          />
        </div>

        <div className={Style.menuVersion}>
          {isDesktop && 'version '}
          {version}
        </div>
        <div className={Style.menuLanguage}>
          <LanguageSwitch />
        </div>

        <div className={Style.terms}>
          <a href='/#/termsandconditions'>{isDesktop ? t('term') : 'T&C'}</a>
        </div>
      </div>
    </div>
  )
}

export default Menu
