import { FC, useEffect, useMemo, useState, useContext, useRef } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Dropdown, FormInput, Icon, Loader, Pagination, Pill, Tooltip } from '@aurecon-creative-technologies/styleguide'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { useMediaQuery } from 'react-responsive'
import { ThemeContext } from '@aurecon-creative-technologies/styleguide/lib/helpers/ThemeContext'
import { ThemeEnum } from '@aurecon-creative-technologies/styleguide/lib/helpers/ThemeSwitcherEnum'

import { IChatResponseModel } from '../models/api/IChatModels'
import { appInsights } from '../api/AppInsights'
import { getChats } from '../api/ChatService'
import Page from '../components/Page'
import NoChatHistory from '../components/NoChatHistory'
import EmptyFilteredHistory from '../components/EmptyFilteredHistory'
import { ChatTypeEnum, ChatTypeToPath } from '../enums/ChatTypeEnum'
import { UI_WIDTH_COLLAPSE } from '../config/config'
import { chatPillDetails } from '../helpers/chatPillDetails'
import { ChatType, QuestionFile, CustomAppsLoader } from '../stores/AppStore'
import { useShowFeature } from '../hooks/useShowFeature'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { formatText } from '../helpers/formatText'
import { subAppRoute } from '../enums/AppRouteConstants'

import nextId from 'react-id-generator'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import Style from '../styles/History.module.sass'

const PAGE_LENGTH = 5
const FILTER = {
  RECALL: 'recall',
  GPT: 'GPT',
  CODE: 'code',
  BAMBOO: 'bamboo',
}

const History: FC = () => {
  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const [chats, setChats] = useState<IChatResponseModel[]>([])
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filters, setFilters] = useState<(string | number)[]>([FILTER.RECALL, FILTER.GPT, FILTER.CODE, FILTER.BAMBOO])
  const [page, setPage] = useState(1)
  const { theme } = useContext(ThemeContext)
  const { t, i18n } = useLanguages()
  const shouldUpdateFiltersRef = useRef(true)
  const customAppsLoader = useRecoilValueLoadable(CustomAppsLoader)

  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const pillInfoTextColor = '#263238'

  const showCodeAssistant = useShowFeature(FeatureFlagEnum.ShowCodeAssistantChat)
  const customAppContents = customAppsLoader.state === 'hasValue' ? customAppsLoader.contents : []

  if (appInsights) appInsights.trackPageView({ name: 'Chat History' })

  useEffect(() => {
    setChatType(null)
    setQuestionFile(null)
  }, [setChatType, setQuestionFile])

  useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true)
      const response = await getChats({})

      if (!response?.data) return

      setChats(response.data)
      setLoading(false)

      if (shouldUpdateFiltersRef.current) {
        const newFilters = new Set([
          FILTER.RECALL,
          FILTER.GPT,
          FILTER.CODE,
          FILTER.BAMBOO,
          ...response.data
            .filter((chat) => chat.type === ChatTypeEnum.CUSTOM_RECALL_APP)
            .map((chat) => `custom_${chat.linkId}`),
        ])
        setFilters(Array.from(newFilters))
        shouldUpdateFiltersRef.current = false
      }
    }

    asyncFunc()
  }, [])

  const openLink = (chat: IChatResponseModel) => {
    location.hash =
      chat.type === ChatTypeEnum.CUSTOM_RECALL_APP && chat.linkId
        ? `#/${ChatTypeToPath[chat.type]}/${chat.linkId}/${subAppRoute.CRA_CHAT}/${chat.id}`
        : `#/${ChatTypeToPath[chat.type]}/${chat.id}`
  }

  const filteredChats = useMemo(() => {
    if (loading) return []

    return chats.filter((chat) => {
      const isCustomApp = chat.type === ChatTypeEnum.CUSTOM_RECALL_APP

      if (isCustomApp) {
        const specificCustomFilterActive = filters.includes(`custom_${chat.linkId}`)
        if (!specificCustomFilterActive) return false
      }

      if (!filters.includes(FILTER.RECALL) && chat.type === ChatTypeEnum.RECALL) return false
      if (!filters.includes(FILTER.GPT) && chat.type === ChatTypeEnum.GPT) return false
      if (!filters.includes(FILTER.CODE) && chat.type === ChatTypeEnum.CODE) return false
      if (!filters.includes(FILTER.BAMBOO) && chat.type === ChatTypeEnum.BAMBOO) return false
      if (!showCodeAssistant.enabled && chat.type === ChatTypeEnum.CODE) return false

      const chatQuestion = chat.firstQuestion.toLowerCase()
      const fileName = chat.filenames[0]?.toLowerCase()
      return chatQuestion.includes(searchText.toLowerCase()) || fileName?.includes(searchText.toLowerCase())
    })
  }, [showCodeAssistant, chats, filters, loading, searchText])

  const chatPages = Math.ceil(filteredChats.length / PAGE_LENGTH)
  const start = (page - 1) * PAGE_LENGTH
  const pageChats = filteredChats.slice(start, start + PAGE_LENGTH)

  const handlePagination = (p: number) => {
    if (!p) return

    setPage(p)
  }

  const filterItems = useMemo(() => {
    const items = [
      { id: FILTER.RECALL, label: 'Recall' },
      { id: FILTER.GPT, label: 'Secure ChatGPT' },
      { id: FILTER.BAMBOO, label: 'Bamboo' },
    ]

    if (showCodeAssistant.enabled) {
      items.push({ id: FILTER.CODE, label: 'Code Assistant' })
    }
    if (customAppsLoader.state === 'hasValue') {
      customAppsLoader.contents?.forEach((app) => {
        items.push({ id: `custom_${app.id}`, label: app.name })
      })
    }

    return items
  }, [customAppsLoader, showCodeAssistant])

  const onSearchChange = (text: string) => {
    setSearchText(text)
    if (appInsights)
      appInsights.trackEvent({
        name: 'History Search',
        properties: { searchText },
      })
  }

  const onFilterChange = (filterVals: (string | number)[]) => {
    setFilters(filterVals)
    if (appInsights)
      appInsights.trackEvent({
        name: 'History Filter',
        properties: { filters: filterVals },
      })
  }

  const renderInfoPills = (chat: IChatResponseModel) => {
    return chat.type === ChatTypeEnum.BAMBOO ? (
      chat.entities.map((entity) => (
        <Tooltip key={nextId()} show={entity}>
          <Pill colour={theme == ThemeEnum.LIGHT ? 1 : 16}>{entity}</Pill>
        </Tooltip>
      ))
    ) : (
      <Pill colour={theme == ThemeEnum.LIGHT ? 1 : 16}>
        {t(`no_question${chat.answerCount > 1 ? '_plural' : ''}`, {
          count: chat.answerCount,
        })}
      </Pill>
    )
  }
  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  return (
    <Page menu contentWrapper>
      <h1>{t('history_header')}</h1>
      <div className={Style.searchFilter}>
        <FormInput
          cssClass='text'
          value={searchText}
          onChange={onSearchChange}
          icon='search'
          placeholder={t('history_input')}
        />
        <div className={Style.filters}>
          <Dropdown
            multiple
            toggleAllItem='Toggle all'
            items={filterItems}
            onSelectMultipleItems={onFilterChange}
            selectedMultipleItems={filters}
            cssClass='history-dropdown-filter'
          />
        </div>
      </div>

      {loading && <Loader label='Loading chats' />}

      {!loading && (
        <>
          {!chats.length && <NoChatHistory />}
          {!!chats.length && !filteredChats.length && <EmptyFilteredHistory />}
          {pageChats.map((chat) => {
            const createDate = format(parseISO(chat.createdAt), 'yyyy/MM/dd HH:mm')
            const customApp = customAppContents?.filter((app) => app.id === chat.linkId)[0]
            const pillDetails = chatPillDetails(chat.type, true, customApp ? customApp.name : '')

            return (
              <div key={chat.id} className={Style.chatItem}>
                <div className={Style.question}>
                  <Icon type='account_circle' outlined />
                  <pre
                    className={Style.questionText}
                    onClick={() => openLink(chat)}
                    onKeyDown={() => openLink(chat)}
                    role='none'
                  >
                    {formatText(chat.type === ChatTypeEnum.BAMBOO ? chat.filenames[0] : chat.firstQuestion)}
                  </pre>
                </div>
                <div className={Style.infoPillHolder}>
                  <Icon type='info' outlined />
                  <div className={Style.info}>
                    <Pill colour={theme == ThemeEnum.LIGHT ? 1 : 16}>{createDate}</Pill>
                    {renderInfoPills(chat)}
                    {customApp?.name && customApp.name.length > 100 ? (
                      <Tooltip key={nextId()} show={customApp?.name || ''}>
                        <Pill
                          colour={1}
                          style={{
                            backgroundColor: pillDetails.pillColour,
                            color: pillInfoTextColor,
                          }}
                        >
                          {pillDetails.pillLabel}
                        </Pill>
                      </Tooltip>
                    ) : (
                      <Pill
                        colour={1}
                        style={{
                          backgroundColor: pillDetails.pillColour,
                          color: pillInfoTextColor,
                        }}
                      >
                        {pillDetails.pillLabel}
                      </Pill>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
          <Pagination
            page={page}
            pageCount={chatPages}
            smallChevron
            onChange={handlePagination}
            cssClass={Style.pagination}
            minimal={!isDesktop}
          />
        </>
      )}
    </Page>
  )
}

export default History
